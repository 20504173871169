import React from 'react'
import Header from './Header'

const OutletFormat = () => {
  return (
    
  <>
  <Header/>
  </>

  )
}

export default OutletFormat